/*

  File: blog/card.js
  Kind: Component
  Description: Card for a blog post

*/

import React from 'react';
import { Link } from 'gatsby';
import CardBg from './card-bg.js';

//import styles from '../../styles/blog.module.css'

const Card = (data) => {
  // console.log(data);
   return (
    <Link to={data.slug} className="group">
      <CardBg featuredImage={data.featuredImage} color={data.color}>
      <div className="flex flex-col h-2/5 min-h-2/ max-h-2/5 p-4 bg-white group-hover:bg-jupiter-900">
          <div className="font-serif font-medium text-saturn-100 group-hover:text-neptune">{data.title}</div>
          <div className="font-sans text-sm overflow-hidden  text-jupiter-100  group-hover:text-jupiter-100">{data.excerpt}</div>
        </div>
      </CardBg>
    </Link>
  );
};

export default Card;
