/*

  File: content/getFragment.js
  Kind: Function
  Description: Get a fragment from allMarkdownRemarkNodes for a spcific fragment ID (caller should have limited to page)
*/

// markup
export default function GetFragment(fragments, fragmentId) {
  //console.log('getFragment',fragments);
  let fragment = fragments.find(f=>f.fields.locator.fragment === fragmentId);
  return (fragment && fragment.body) || ``;
}
    
