/*

  File: layouts/grid_2c_2r_3c_1rh.js
  Kind: Component / Layouts
  Description: 2 columns, 2 rows, 3 cells, 1 row merged into header

*/

import * as React from 'react';

//Components

const GridCards = ({children}) => {

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-12 ">
      {children}
    </div>
  )

}

export default GridCards
