/*

  File: components/blog/pagination.js
  Kind: Component
  Description: Show list of pages available as individual links

*/

import React from 'react';
import { Link } from 'gatsby';

//Font awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../util/fontawesome.js';


const Pagination = ({i, numberPages}) => {

  if (numberPages <= 1) {
    //no pagination required, return empty div (in case messes with page layout)
    return (
      <div></div>
    );
  }

  const icons = {
    prev: 'angle-double-left',
    next: 'angle-double-right',
  };

  //this navLink could be extracted as a component, and resued with the one for next, previous post in templates/blog/post.js - but that may be overcomplex
  const navLink = (direction) => {
    if ((direction === 'prev' && i <= 1) || (direction === 'next' && i === numberPages)) {
      //don't need an icon for previous page when = 0, or next when on last page
      return (
        <div></div>
      );
    } else {
      const i_ = (direction === 'prev') ? i - 1 : i + 1;                //set new page number
      const path = '/blog' + ((i_ <= 1) ? '' : '/' + i_);                      //handle pages as being blog, blog/2, blog/3  - there is no blog/1
      const icon = icons[direction];
      return (
        <div className="mx-8">
          <Link to={path} className="">
            <FontAwesomeIcon icon={['fas', icon]} className="text-indigo-500" />
          </Link>
        </div>
      );
    }
  };

  return (
    <div className="flex flex-row justify-between">
      {navLink('prev')}
      {Array.from({ length: numberPages}, (_,i) => (
        <Link key={`pagination${i+1}`} to={i === 0 ? "/blog" : '/blog/' + (i + 1)}>
          {i+1}
        </Link>
      ))}
      {navLink('next')}
    </div>
  )



}

export default Pagination