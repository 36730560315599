/*

  File: blog/card.js
  Kind: Component
  Description: Card for a blog post

*/

import React from 'react';
import { BgImage } from 'gbimage-bridge';

const CardBg = ({featuredImage, color, children}) => {

  const bgColor = color || `bg-white`
  // console.log(featuredImage);
  if (featuredImage) {
    const imageData = featuredImage.childImageSharp.gatsbyImageData
    return (
      <BgImage className={"flex flex-col-reverse h-60 shadow hover:shadow-md  " + bgColor} image={imageData}>
        {children}
      </BgImage> 
    )
  } else {
    return (
      <div className={"flex flex-col-reverse h-60 shadow hover:shadow-md  " + bgColor}>
        {children}
      </div>
    )
  }

};

export default CardBg;
