/*

  File: templates/blog/posts.js
  Kind: Template
  Description: Template for creating paginated blog post pages - contents will reside at /blog - /blog/{n}

*/

import * as React from 'react';
import { graphql } from 'gatsby';

//Components
import Layout from '../../components/layout.js';
import GridCards from '../../components/layouts/grid_cards.js'
import Card from '../../components/blog/card.js';
import Pagination from '../../components/blog/pagination.js'
import { MDXRenderer } from 'gatsby-plugin-mdx';


//Utilities
import GetFragment from '../../components/content/getFragment.js'

// markup
const Blog = ({data, location, pageContext}) => {


  const { site, posts, fragments } = data;

  // console.log('FRAGMENTS:', fragments)

  const Posts = posts.edges
    .filter(edge => site.siteMetadata.env === 'development' || edge.node.frontmatter.published) // You can filter your posts based on some criteria
    .map((edge) => {
      // const featuredImage = edge.node.frontmatter.featured_image && edge.node.frontmatter.featured_image.childImageSharp.fluid.src;
      const excerpt = edge.node.frontmatter.excerpt || edge.node.excerpt;
      return <Card key={edge.node.fields.slug} slug={edge.node.fields.slug} title={edge.node.frontmatter.title} excerpt={excerpt} color={edge.node.frontmatter.color} featuredImage={edge.node.frontmatter.featured_image}/>;
    });

  const Fragments = fragments.nodes;

  return (
    <Layout pageTitle="essays" location={location}>
      <div className="mb-12">
      <MDXRenderer>
      {GetFragment(Fragments,'intro')}
      </MDXRenderer>
      </div>
      <GridCards>
        {Posts}
      </GridCards>
      <div className="grid grid-cols-4 my-8">
        <div id="spacer"></div>
        <div className="col-span-2">
          <Pagination i={pageContext.currentPage} numberPages={pageContext.numPages}/>
        </div>
        <div id="spacer"></div>
      </div>
    </Layout>
  );
};

export default Blog;

export const pageQuery = graphql`query allPosts($skip: Int!, $limit: Int!) {
  site {
    siteMetadata {
      env
    }
  }
  posts: allMdx(
    filter: {fields: {collection: {eq: "blog"}}}
    sort: {fields: frontmatter___date, order: DESC}
    limit: $limit
    skip: $skip
  ) {
    edges {
      node {
        frontmatter {
          title
          date
          excerpt
          featured_image {
            name
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG, layout: CONSTRAINED)
            }
          }
          published
        }
        fields {
          slug
        }
        excerpt
      }
    }
  }
  fragments: allMdx(
    filter: {fields: {collection: {eq: "fragment"}, locator: {page: {eq: "blog"}}}}
  ) {
    nodes {
      body
      fields {
        locator {
          fragment
        }
      }
    }
  }
}
`;
